import {
	createRouter,
	createWebHistory,
	RouteLocationNormalized,
} from "vue-router";
import Home from "../views/LandingPage.vue";
import HomeFire from "../views/TopoFireLandingPage.vue"
import storeModule from "../store/store";
import { parseService } from "../services/parse.service";
import { teamVault } from "@/vault/Teams";
import { coreData } from "@/services/CoreData";
import { useToast } from "vue-toastification";

let homePage = Home;
if (parseService.isTopoFireApp()) {
	homePage = HomeFire;
}

function helpPageId() {
	if (parseService.isTopoFireApp()) {
		return "tf_help";
	} else {
		return "Help";
	}
}

function indexPage() {
	if (parseService.isTopoFireApp()) {
		return "/indexTopoFire.html";
	} else {
		return "/index.html";
	}
}

function subscriptionProPage() {
	if (parseService.isTopoFireApp()) {
		return import("../views/TopoFirePricing.vue");
	} else {
		return import("../views/Subscriptions/SubscriptionPro.vue");
	}
}

const routes = [
	{
		path: "/",
		name: "home",
		component: homePage, 
		alias: indexPage(),
	},
	// {
	// 	path: "/iphone",
	// 	name: "iphone",
	// 	component: () => import("../views/iPhoneFeatures.vue"),
	// 	alias: "/iPhone.html",
	// },
	// {
	// 	path: "/ipad",
	// 	name: "ipad",
	// 	component: () => import("../views/iPadFeatures.vue"),
	// },
	// {
	// 	path: "/mac",
	// 	name: "mac",
	// 	component: () => import("../views/MacFeatures.vue"),
	// },
	// {
	// 	path: "/watch",
	// 	name: "watch",
	// 	component: () => import("../views/WatchFeatures.vue"),
	// },
	{
		path: "/help1",
		name: "help1",
		component: () => import("../views/HelpHome.vue"),
	},
	{
		path: "/manual",
		name: "manual",
		component: () => import("../views/TopoManual.vue"),
	},
	{
		path: "/allPlatformProUpgrade",
		name: "all-platform-pro-upgrade",
		component: () => import("../views/AllPlatformProUpgrade.vue"),
	},
	{
		path: "/contactus",
		name: "contact-us",
		component: () => import("../views/ContactUs.vue"),
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () => import("../views/PrivacyStatement.vue"),
	},
	{
		path: "/purchase",
		name: "purchase",
		redirect: "/purchase.html",
	},
	{
		path: "/maptest",
		name: "maptest",
		component: () => import("../components/modular-page/content-element/MapLibreTest.vue"),
	},
	{
		path: "/teams",
		name: "teams",
		component: () => import("../views/TeamsOverview.vue"),
	},
	{
		path: "/downloadTopoFire",
		name: "downloadTopoFire",
		component: () => import("../views/downloadTopoFire.vue"),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "toc"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/toc",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "toc"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/prefix",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "prefix"
		}),
	},

	{
		path: "/nwcg-incident-response-pocket-guide/operational-engagement",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "green"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/specific-hazards",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "gray"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/fire-environment",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "orange"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/all-hazard-response",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "yellow"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/aviation",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "blue"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/other-references",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "white"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/emergency-medical-care",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "red"
		}),
	},
	{
		path: "/nwcg-incident-response-pocket-guide/back-cover",
		component: () => import("../views/TopoFireIRPG.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: "back"
		}),
	},



	// {
	// 	path: "/nwcg-incident-response-pocket-guide/:sectionId",
	// 	component: () => import("../views/TopoFireIRPG.vue"),
	// 	props: (route: RouteLocationNormalized) => ({			
	// 		sectionId: route.params.sectionId,
	// 	}),
	// },

	{
		path: "/watch",
		name: "watch",
		component: () => import("../views/AppleWatchOverview.vue"),
	},
	{
		path: "/visionPro",
		name: "visionPro",
		component: () => import("../views/AppleVisionProOverview.vue"),
	},
	{
		path: "/plans",
		name: "plans",
		component: () => import("../views/Plans.vue"),
	},

	{
		path: "/pricing",
		name: "pricing",
		component: () => import("../views/TopoFirePricing.vue"),
	},

	{
		path: "/purchaseSuccess",
		name: "purchaseSuccess",
		component: () => import("../views/PurchaseSuccess.vue"),
	},
	{
		path: "/newsletterConfirmation",
		name: "newsletterConfirmation",
		component: () => import("../views/NewsletterConfirmation.vue"),
	},
	{
		path: "/test",
		name: "test",
		component: () =>
			import("../components/modular-page/content-element/TestVue.vue"),
	},
	{
		path: "/test2",
		name: "test2",
		component: () =>
			import("../components/modular-page/content-element/TestVue2.vue"),
	},
	{
		path: "/test2",
		name: "test2",
		component: () =>
			import("../components/modular-page/content-element/TestVue2.vue"),
	},
	{
		path: "/settings",
		name: "settings",
		component: () => import("../views/GPSettings.vue"),
	},
	{
		path: "/tos",
		name: "terms-of-service",
		component: () => import("../views/TermsOfService.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../views/LoginPage.vue"),
	},
	{
		path: "/account-create",
		name: "account-create",
		component: () => import("../views/AccountCreate.vue"),
	},
	{
		path: "/account-settings",
		name: "account-settings",
		component: () => import("../views/AccountSettings.vue"),
	},
	{
		path: "/account/manage-subscription",
		name: "account",
		component: () => import("../views/Subscriptions/ManageSubscription.vue"),
	},
	{
		path: "/store/free-trial",
		component: () => import("../views/FreeTrial.vue"),
		children: [
			{
				path: "pro",
				component: () => import("../components/trial-page/ProTrial.vue"),
			},
			{
				path: "hunt",
				component: () => import("../components/trial-page/HuntTrial.vue"),
			},
		],
	},
	{
		path: "/forgot-password",
		name: "forgot-password",
		component: () => import("../views/ForgotPassword.vue"),
	},
	{
		path: "/proUpgrade",
		name: "proUpgrade",
		component: () => import("../views/ProUpgrade.vue"),
	},
	{
		path: "/huntUpgrade",
		redirect: "/subscriptions-hunt",
	},
	{
		path: "/renewal",
		name: "renewal",
		component: () => import("../views/RenewalPage.vue"),
	},
	{
		path: "/store",
		component: () => import("../views/GPMainStore.vue"),
		props: (route: RouteLocationNormalized) => ({
			query: route.query,
		}),
	},
	{
		path: "/store/content",
		component: () => import("../components/modular-page/GPPageContent.vue"),
		props: (route: { query: { productid: any } }) => ({
			productid: route.query.productid,
		}),
	},
	{
		path: "/store/product/:productid",
		component: () => import("../components/modular-page/GPPageContent.vue"),
		props: (route: RouteLocationNormalized) => ({
			productid: route.params.productid,
		}),
	},
	{
		path: "/store/page/:pageid",
		component: () => import("../views/GPMainStore.vue"),
		props: (route: RouteLocationNormalized) => ({
			query: { pageid: route.params.pageid },
		}),
	},
	{
		path: "/store/free-trial",
		component: () => import("../views/FreeTrial.vue"),
		children: [
			{
				path: "pro",
				component: () => import("../components/trial-page/ProTrial.vue"),
			},
			{
				path: "hunt",
				component: () => import("../components/trial-page/HuntTrial.vue"),
			},
		],
	},
	{
		path: "/info",
		name: "articleSummary",
		component: () => import("../components/RecentWPGrid.vue"),
	},
	{
		path: "/info/:slug",
		name: "articleView",
		component: () => import("../views/WPArticleView.vue"),
		props: (route: RouteLocationNormalized) => ({
			slug: route.params.slug,
		}),
	},
	{
		path: "/share/:shareId",
		name: "share",
		component: () => import("../views/ShareView.vue"),
		props: (route: RouteLocationNormalized) => ({
			shareId: route.params.shareId,
		}),
	},
	{
		path: "/map/team/:teamId",
		name: "teamMap",
		component: () => import("../views/MapView.vue"),
		props: (route: RouteLocationNormalized) => ({
			teamId: route.params.teamId,
		}),
	},
	{
		path: "/map/user/:userId",
		name: "userMap",
		component: () => import("../views/MapView.vue"),
		props: (route: RouteLocationNormalized) => ({
			userId: route.params.userId,
		}),
	},
	{
		// TODO: May need to remove
		path: "/view-team",
		component: () => import("../views/ViewTeam.vue"),
		props: (route: RouteLocationNormalized) => ({
			uuid: route.query.uuid,
		}),
	},
	{
		path: "/jointeam",
		component: () => import("../views/JoinTeam.vue"),
		props: (route: RouteLocationNormalized) => ({
			code: route.query.code,
			isIncident: false
		}),
	},
	{
		path: "/joinincident",
		component: () => import("../views/JoinTeam.vue"),
		props: (route: RouteLocationNormalized) => ({
			code: route.query.code,
			isIncident: true
		}),
	},
	{
		path: "/resetPassword",
		component: () => import("../views/PasswordReset.vue"),
		props: (route: RouteLocationNormalized) => ({
			code: route.query.code,
		}),
	},
	{
		path: "/subscriptions-free",
		name: "subscriptions-free",
		component: () => import("../views/Subscriptions/SubscriptionFree.vue"),
	},
	{
		path: "/subscriptions-pro",
		name: "subscriptions-pro",
		component: () => subscriptionProPage(),
	},
	{
		path: "/pro",
		name: "pro",
		component: () => import("../views/Subscriptions/SubscriptionPro.vue"),
	},
	{
		path: "/pro-full",
		name: "pro-full",
		component: () => import("../views/Subscriptions/SubscriptionStorePro.vue"),
	},
	{
		path: "/subscriptions-hunt",
		name: "subscriptions-hunt",
		component: () => import("../views/Subscriptions/SubscriptionElite.vue"),
	},
	{
		path: "/subscriptions-elite",
		name: "subscriptions-elite",
		component: () => import("../views/Subscriptions/SubscriptionElite.vue"),
	},
	{
		path: "/subscriptions-essentials",
		name: "subscriptions-essentials",
		component: () => import("../views/Subscriptions/SubscriptionEssentials.vue"),
	},
	{
		path: "/admin",
		name: "admin",  
		component: () => import("../views/AdminMain.vue"),
		props: (route: RouteLocationNormalized) => ({
			userSearch: route.query.userSearch,
		}),
	},
	{
		path: "/admin/user/:userId",
		component: () => import("../components/UserView3.vue"),
		props: (route: RouteLocationNormalized) => ({
			userId: route.params.userId,
		}),
	},

	{
		path: "/admin/user/:userId/merge",
		component: () => import("../components/UserMerge.vue"),
		props: (route: RouteLocationNormalized) => ({
			userId: route.params.userId,
		}),
	},
	// {
	// 	path: "/admin/user2/:userId",
	// 	component: () => import("../components/UserView3.vue"),
	// 	props: (route: RouteLocationNormalized) => ({
	// 		userId: route.params.userId,
	// 	}),
	// },
	{
		path: "/help/section/:sectionId",
		component: () => import("../views/SupportSection.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: route.params.sectionId,
		}),
	},
	{
		path: "/help",
		component: () => import("../views/SupportSection.vue"),
		props: (route: RouteLocationNormalized) => ({			
			sectionId: helpPageId(),
		}),
	},
	{
		path: "/help/:pageid",
		component: () => import("../views/HelpArticles.vue"),
		props: (route: RouteLocationNormalized) => ({
			pageid: route.params.pageid,
		}),
	},
	{
		path: "/iphone.html",
		redirect: "/iphone",
	},
	{
		path: "/ipad.html",
		redirect: "/ipad",
	},
	{
		path: "/mac.html",
		redirect: "/mac",
	},
	{
		path: "/watch.html",
		redirect: "/watch",
	},
	{
		path: "/help.html",
		redirect: "/help",
	},
	{
		path: "/support.html",
		redirect: "/help",
	},
	{
		path: "/support",
		redirect: "/help",
	},
	{
		path: "/manual.html",
		redirect: "/manual",
	},
	// {
	//   path: "/logout"
	// }
];
//  Make sure page is scrolled to top when nav-links are called
// const scrollBehavior = function () {
//   return {
//     left: 0,
//     top: 0
//   };
// };

const router = createRouter({
	history: createWebHistory(/* process.env.BASE_URL */),
	routes: routes,
	scrollBehavior() {
		return {
			left: 0,
			top: 0,
		};
	},
});

function checkInAppLinks(to: RouteLocationNormalized): boolean {
	const ua = navigator.userAgent;
	const mobile =
		ua.indexOf("iPad") >= 0 ||
		ua.indexOf("iPhone") >= 0 ||
		ua.indexOf("iPod") >= 0;
	if (mobile) {
		if (to.path === "/proUpgrade" || to.path === "/subscriptions-pro") {
			window.location.href = "glacierpeak://subscriptions-pro";
			return true;
		}
		if (to.path === "/huntUpgrade" || to.path === "/subscriptions-hunt") {
			window.location.href = "glacierpeak://subscriptions-hunt";
			return true;
		}
		if (to.path === "/renewal") {
			window.location.href = "glacierpeak://subscriptions";
			return true;
		}
		if (to.path === "/store/free-trial/startprotrial") {
			window.location.href =
				"glacierpeak://subscription?level=pro&free-trial=true";
			return true;
		}
		if (to.path === "/store/free-trial/starthunttrial") {
			window.location.href =
				"glacierpeak://subscription?level=hunt&free-trial=true";
			return true;
		}
		return false;
	}
	return false;
}

const privatePages = ["/settings", "/admin"];

const adminPages = ["/admin"];

const newTabPaths = ["/url", "/video"];

function getSlugFromURL(url: string): string {
	const urlSplit = url.split("/");
	let slug = urlSplit.pop();
	while (slug !== undefined && slug.length === 0) {
		slug = urlSplit.pop();
	}
	return slug || "";
	// return next(`/info/${slug}`);
}

const toast = useToast();

router.beforeEach(async (to, from, next) => {
	if (checkInAppLinks(to)) {
		return next(from.fullPath);
	}

	if (to.path === "/store/free-trial") {
		window.location.href = `http://${window.location.host}/info/how-the-free-trial-works/`;
		// "https://glacierpeak.app/info/how-the-free-trial-works/";
		return next(from.fullPath);
	}

	if (to.path === "/store/free-trial/startprotrial") {
		window.location.href =
			"https://www.support.glacierpeak.app/how-the-free-trial-works/";
		return next(from.fullPath);
	}
	if (to.path === "/store/free-trial/starthunttrial") {
		window.location.href = `http://${window.location.host}/info/how-the-free-trial-works/`;
		// "https://www.support.glacierpeak.app/how-the-free-trial-works/";
		// return next();
		// return true;
		return next(from.fullPath);
	}

	if (to.path === "/store?subscription=pro") {		
		window.location.href = `${window.location.protocol}://${window.location.host}/pro`;
		// "https://www.support.glacierpeak.app/how-the-free-trial-works/";
		// return next();
		// return true;
		return next(from.fullPath);
	}

	if (to.path === "/emailsupport") {
		window.location.href =
			"mailto:support@glacierPeakStudios.com?body=%0A%0ASent%20via%20glacierpeak.app%20support%20link.";
		return next(from.fullPath);
	}

	if (to.path === "/teamsinfomail") {
		window.location.href =
			"mailto:support@glacierPeakStudios.com?subject=Teams Inquiry";
		return next(from.fullPath);
	}

	if (to.path.includes("/createGpksShareFile")) {
		// console.log("HEHEHE");
		//localhost:8080/createGpksShareFile?shareId=hHqEwqzga4
		const inAppLink = `glacierpeak://createGpksShareFile?shareId=${to.query.shareId}`;
		window.location.href = inAppLink;
		console.log("inAppLink:", inAppLink);
		console.log("window href:", window.location.href);

		// return true;
		return next(from.fullPath);
	}

	if (to.path === "/article") {
		const url = to.query.url?.toString() || "";
		const slug = getSlugFromURL(url);
		return next(`/info/${slug}`);
	}

	if (to.path.startsWith("/www.support")) {
		const slug = getSlugFromURL(to.path);
		return next(`/info/${slug}`);
	}

	// Open specified external link in new tab
	if (newTabPaths.includes(to.path)) {
		location.replace(String(to.query.url));
	}

	if (to.path === "/mapStore") {
		const sku = to.query.sku as string;
		if (sku.startsWith("NatGeo")) {
			router.push({path: "/"});
		} else {
			parseService.getProdIdForSku(sku).then((prodId) => {
				router.push({ path: "/store/content", query: { productid: prodId } });
			});
		}
	}

	// Login checks
	// storeModule.commit("account/clearMessages");
	if (to.path === "/logout") {
		teamVault.clearCache();
		storeModule.dispatch("account/logout");
		toast.info("Logged out");
		return next("/login");
	}

	const user = await coreData.getCurrentUser();
	// redirect to login page if not logged in and trying to access a restricted page
	const authRequired = privatePages.includes(to.path);
	const loggedIn = storeModule.getters["account/isLoggedIn"];
	const isAdmin = user?.isAdmin ?? false;

	if (authRequired && !loggedIn) {
		return next("/login");
	}

	if (to.path.startsWith("/admin") && !isAdmin) {
		return next("/");
	}

	// Don't let users log in again
	if (to.path === "/login" && loggedIn) {
		return next("/");
	}

	next();
});

export default router;
