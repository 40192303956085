import GPSubscription from "@/data/GPSubscription";
import GPUser from "@/data/GPUser";
import { ParseObject, ParseUser } from "@/data/ParseTypes";
import { HistoryResponse, StatusResponse } from "@/data/StoreServerUtils";
import GPTeam from "@/data/teams/GPTeam";
import GPTeamMember from "@/data/teams/GPTeamMember";
import GPTeamRole from "@/data/teams/GPTeamRole";
import Parse from "parse";
import { GPMapWaypoint } from '@/data/GPMapWaypoint';

// let APP_ID = "";
// let JS_KEY = "";

//  Production keys
// eslint-disable-next-line prefer-const
// let APP_ID = "TXBGm2OBL4UsoZrztji3kQA02R6Jj4cjntEhuCY5";
// eslint-disable-next-line prefer-const
// let JS_KEY = "SzqrdRmsNBwA54XOWQx1IS0bez2ml2byhGEp837T";
// if (process.env.NODE_ENV === "development") {
// 	// Dev keys
// const	APP_ID = "PSMTpHcn6Lj5xWKq1EeYLhJW7znJd8mOFcZIQobq";
// const	JS_KEY = "u73GyoW2fFnK2InhGuNUfCJFSrfIfmIZJqaxIcma";
// }

// Dev keys
// const APP_ID = "PSMTpHcn6Lj5xWKq1EeYLhJW7znJd8mOFcZIQobq";
// const JS_KEY = "u73GyoW2fFnK2InhGuNUfCJFSrfIfmIZJqaxIcma";

Parse.initialize(appID(), jsKey());
Parse.serverURL = getParseServerURL();

export const parseService = {
	login,
	logout,
	registerUser,
	confirmNewsletterSignup,
	getUserProperty,
	isLoggedIn,
	isTopoFireApp,
	getCurrentUser,
	updateUser,
	updateUserMany,
	saveUser,
	setPassword,
	passwordReset,
	setAvatar,
	getStorePage,
	getStorePageSections,
	getStoreSection,
	getStorePageContent,
	getStoreProduct,
	getStoreContent,
	storeSearch,
	helpSearch,
	getJWT,
	genericEqualsQuery,
	genericContainsQuery,
	searchGPContent,
	getProdIdForSku,
	getTeamMemberInfo,
	getMembersForTeam,
	getRawTeamMemberInfo,
	userJoinWithCode,
	checkTeamCode,
	createTeamJoinInvite,
	getUserPropertyAlt,
	getTeamByUUID,
	getTeamByID,
	getMemberByID,
	getRoleByID,
	getGenericTeamCode,
	removeTeamMember,
	getUserByID,
	requestPasswordReset,
	resetPassword,
	checkPasswordResetCode,
	getRolesForTeam,
	updateTeamMember: updateMemberAttribute,
	updateMemberAttributes,
	updateMemberRole,
	getSubscriptionsForUser,
	searchGPUsers,
	setUserEmail,
	clearUserData,
	deleteUserData,
	getUserCount,
	getActiveSubscriptionCount,
	mergeUserData,
	getAppStoreHistory,
	getAppStoreSubscriptionStatus,
	runParseFunction,
	getShareSidebarData,
	getBack4AppHost,
	checkFor20OffDiscountForCurrentUser,
	getTeamMembers,
	getTeamData,
	getUserData
};

function isTopoFireApp(): boolean {
	// return true;

	const host = window.location.origin;
	if (host.indexOf("topofire.com") >= 0) {
		return true;
	}
	return false;
}

function isProductionEnvironment(): boolean {

	//If you want to change b4a to test against production data
	//change this function to always return true.
	// return true;

	const host = window.location.origin;
	if (host.indexOf("staging.topomaps.co") >= 0) {
		return true;
	} else if (host.indexOf("dev.topomaps.co") >= 0) {
		return false;
	} else if (host.indexOf("topomaps.co") >= 0) {
		return true;
	} else if (host.indexOf("topofire.com") >= 0) {
		return true;
	} 

	return false;
}

function isLocalDevEnvironment(): boolean {
	if (!isProductionEnvironment()) {
		const host = window.location.origin;
		if (host.indexOf("localhost:") >= 0) {
			return true;
		}
	}
	return false;
}

function appID(): string {
	if (isProductionEnvironment()) {
		if (isTopoFireApp()) {
			return "J89X0dtvIZgllFETRE8LHl3mjNvRaaddxOiI03Xd";
		}
		return "TXBGm2OBL4UsoZrztji3kQA02R6Jj4cjntEhuCY5";
	} 
	return "PSMTpHcn6Lj5xWKq1EeYLhJW7znJd8mOFcZIQobq";
}

function jsKey(): string {
	if (isProductionEnvironment()) {
		if (isTopoFireApp()) {
			return "TfNt8HzaMwekmMJkYnoIVxy37fyIMBhZOS7cjWRs";
		}
		return "SzqrdRmsNBwA54XOWQx1IS0bez2ml2byhGEp837T";
	}
	return "u73GyoW2fFnK2InhGuNUfCJFSrfIfmIZJqaxIcma";
}

function getParseServerURL() {
	if (isLocalDevEnvironment()) {
		return "http://localhost:1337/parse";
	}

	return "https://parseapi.back4app.com";
}

function getBack4AppHost(): string {
	const productionHost = "https://topomaps.back4app.io";
	const devHost = "https://tmdev.back4app.io";
	const fireHost = "https://topofire.b4a.app";
	const localHost = "http://localhost:1337"
	if (isTopoFireApp()) {
		return fireHost;
	} else if (isProductionEnvironment()) {
		return productionHost	
	}  else if (isLocalDevEnvironment()) {
		return localHost;
	}
	return devHost;
}

function login(username: string, password: string): Promise<ParseUser> {
	return Parse.User.logIn(username, password);
}

async function logout(): Promise<void> {
	await Parse.User.logOut();
}

async function registerUser(user: GPUser): Promise<ParseUser> {
	const newUser = new Parse.User();
	const userProps = Object.entries(user);
	for (const [property, value] of userProps) {
		newUser.set(property, value);
	}
	newUser.set("email", user.username);
	const puser = await newUser.signUp();
	await Parse.Cloud.run("updateMarketingFieldsFromDataOnServerForUser", {
		id: puser.id,
		okToAddNewUser: true
	});

	return puser;
}

async function confirmNewsletterSignup(userId: string): Promise<void> {
	await Parse.Cloud.run("updateMarketingFieldsFromDataOnServerForUser", {
		id: userId,
		okToAddNewUser: true,
		subscribeToEmails: true
	});
}

function getCurrentUser(): ParseUser | undefined {
	return Parse.User.current();
}

async function getMembersForTeam(teamID: string): Promise<GPTeamMember[]> {
	console.log("Fetching members from Parse!")
	const memberQuery = new Parse.Query("GPTeamMembers");
	const GPTeam = Parse.Object.extend("GPTeam");
	const tm = new GPTeam();
	tm.id = teamID;
	memberQuery.equalTo("team", tm);
	// memberQuery.include("member");
	const memberObjs = await memberQuery.find();

	const memberProfiles: GPTeamMember[] = [];

	try {
		if (memberObjs) {
			for (const memberObj of memberObjs) {
				const member = await GPTeamMember.newFromParseObj(memberObj);
				if (member != null && member.status == "active") {
					memberProfiles.push(member);
				}
			}
		}
	} catch (err) {
		console.log(err);
		// return err;
		return [];
	}

	return memberProfiles;
}

async function getTeamMemberInfo(
	userID?: string
): Promise<GPTeamMember[] | undefined> {
	let usrID: string | undefined;
	if (userID) {
		usrID = userID;
	} else {
		usrID = Parse.User.current()?.id;
	}
	if (usrID) {
		// const usrID = user.id;
		const memberQuery = new Parse.Query("GPTeamMembers");
		const user = new Parse.User();
		user.id = usrID;
		memberQuery.equalTo("member", user);
		// memberQuery.include("member")

		// const userObj = new Parse.
		// memberQuery.include("role");
		// memberQuery.include("team");
		const memberObjs = await memberQuery.find();
		// memberQuery.matches("title", searchRegex);
		// memberQuery.matchesKeyInQuery("member", userID);
		// memberQuery.equalTo("active", true);
		// memberQuery.equalTo("app_section", section);
		const memberProfiles: GPTeamMember[] = [];
		// const memberObjs = await genericEqualsQuery("GPTeamMembers", {
		// 	"member.objectId": user,
		// });
		for (const memberObj of memberObjs) {
			const member = await GPTeamMember.newFromParseObj(memberObj);
			if (member != null && member.status == "active") {
				memberProfiles.push(member);
			}
		}

		return memberProfiles;
	}
	return undefined;
}

async function getRawTeamMemberInfo(): Promise<ParseObject[] | undefined> {
	const user = Parse.User.current();
	if (user) {
		const memberQuery = new Parse.Query("GPTeamMembers");
		memberQuery.equalTo("member", user);
		return memberQuery.find();
	}
}

async function checkFor20OffDiscountForCurrentUser(): Promise<boolean> {
  const user = Parse.User.current();
  if (user != null) {
    const query1 = new Parse.Query("GPSubscriptionPromo");
    query1.doesNotExist("user");
    query1.equalTo("active", true);

    const query2 = new Parse.Query("GPSubscriptionPromo");
    query2.equalTo("user", user);
    query2.equalTo("active", true);

    const query = Parse.Query.or(query1, query2);
    query.limit(50);

    const discounts = await query.find();
		// console.log("Discounts", discounts);
    if (discounts != null) {
			// console.log("Found discounts " + discounts.length);
      let matching = null;
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (disountConditionsMeet(discount)) {
          matching = discount;
          break;
        }
      }

			// console.log("Matching ",matching);
      if (matching != null) {				
        const promos = matching.get("promo");
				if (promos != null && promos.includes("20off")) {
					// console.log("Has 20% promo");
					return true;
				}
      }
    }
  }

  return false;
}

function disountConditionsMeet(discount: ParseObject): boolean {
	// console.log("disountConditionsMeet", discount);
	const active = discount.get("active");
	if (active) {
		const today = new Date();
		const endDate = discount.get("endDate");
		if (endDate != null) {
			// console.log("checking endDate", endDate);
			if (endDate.getTime() < today.getTime()) {
				// console.log("condition not meet");
				return false;
			}
		}

		const startDate = discount.get("startDate");
		if (startDate != null) {
			// console.log("checking startDate", startDate);
			if (startDate.getTime() > today.getTime()) {
				// console.log("condition not meet");
				return false;
			}
		}

		// console.log("Conditions meet");
		return true;
	}
  return false;
}

function getUserProperty(property: string): any {
	if (!isLoggedIn()) {
		return undefined;
	}
	const user = Parse.User.current();
	if (user) {
		return user.get(property);
	}
	return undefined;
}

function getUserPropertyAlt(user: ParseUser, property: string): any {
	if (!isLoggedIn()) {
		return undefined;
	}
	// const user = Parse.User.current();
	if (user) {
		return user.get(property);
	}
	return undefined;
}

function isLoggedIn(): boolean {
	const user = Parse.User.current();
	if (user) {
		return true;
	} else {
		return false;
	}
}

function updateUser(attr: string, item: any): void {
	const user = Parse.User.current();
	if (user) {
		console.log("parseService.updateUser: ", attr, item);
		user.set(attr, item);
		if (attr === "username") {
			user.set("email", item);
			user.set("username", item);
		}
		user.save();
	}
}

async function updateMemberAttribute(
	memberID: string,
	attr: string,
	item: any
): Promise<void> {
	const memberQuery = new Parse.Query("GPTeamMembers");
	const member = await memberQuery.get(memberID);
	member.set(attr, item);
	member.save();
}

async function updateMemberAttributes(
	memberID: string,
	attribs: Array<{ attr: string; value: any }>
): Promise<void> {
	const memberQuery = new Parse.Query("GPTeamMembers");
	const member = await memberQuery.get(memberID);
	for (const attr of attribs) {
		member.set(attr.attr, attr.value);
	}
	member.save();
}

async function updateMemberRole(member: GPTeamMember): Promise<void> {
	const roleQuery = new Parse.Query("GPTeamRole");
	const roleFull = await roleQuery.get(member.roleID);
	const memberQuery = new Parse.Query("GPTeamMembers");
	const pMember = await memberQuery.get(member.id);
	pMember.set("role", roleFull);
	pMember.save();
}

function setPassword(newPasswd: string): boolean {
	const user = Parse.User.current();
	if (user) {
		return user.setPassword(newPasswd);
	}
	return false;
}

function saveUser(): void {
	const user = Parse.User.current();
	if (user) {
		user.save();
	}
}

async function getUserByID(uid: string): Promise<ParseUser> {
	const userQuery = new Parse.Query(Parse.User);
	const user = await userQuery.get(uid);

	return user;
}

function updateUserMany(attr: string, items: any[]): void {
	let x = 1;
	for (const atrib of attr) {
		updateUser(atrib, items[x]);
		x++;
	}
}

function passwordReset(email: string): Promise<ParseUser> {
	return Parse.User.requestPasswordReset(email);
}

function setAvatar(
	name: string,
	file:
		| number[]
		| { base64: string }
		| { size: number; type: string }
		| { uri: string }
): Promise<Parse.File> {
	const parseFile = new Parse.File(name, file);
	return parseFile.save();
}
async function getStorePage(pageId: string): Promise<ParseObject | undefined> {
	const storeQuery = new Parse.Query("GPStorePage");
	storeQuery.equalTo("identifier", pageId);
	return storeQuery.first();
}

function getStorePageSections(pageId: string): Promise<ParseObject[]> {
	return genericEqualsQuery("GPStoreSection", {
		pageId: pageId,
	});
}

function getStoreSection(objectId: string): Promise<ParseObject> {
	return getParseObjectById(objectId, "GPStoreSection");
}

function getStorePageContent(
	objectId: string
): Promise<ParseObject | undefined> {
	const contentQuery = new Parse.Query("GPStoreContent");
	return contentQuery.get(objectId);
}

function getStoreProduct(productId: string): Promise<ParseObject | undefined> {
	const contentQuery = new Parse.Query("GPStoreContent");
	contentQuery.equalTo("productId", productId);
	return contentQuery.first();
}

function getStoreContentSku(sku: string): Promise<ParseObject[]> {
	return genericEqualsQuery("GPStoreContent", {
		publisherSKU: sku,
	});
}

function getStoreContent(objectId: string): Promise<ParseObject[]> {
	return genericEqualsQuery("GPStoreContent", {
		objectId: objectId,
	});
}

async function getProdIdForSku(sku: string): Promise<string> {
	const results = await genericEqualsQuery("GPStoreContent", {
		publisherSKU: sku,
	});
	if (results.length > 0) {
		const pfCont = results[0];
		return pfCont.get("productId") as string;
	}
	return "";
}

function getParseObjectById(
	objectId: string,
	className: string
): Promise<ParseObject> {
	const query = new Parse.Query(className);
	return query.get(objectId);
}

function genericEqualsQuery(
	className: string,
	queryVals: {
		[x: string]: any;
	}
): Promise<ParseObject[]> {
	const query = new Parse.Query(className);
	for (const key in queryVals) {
		query.equalTo(key, queryVals[key]);
	}
	query.equalTo("active", true);

	return query.find();
}

function genericContainsQuery(
	className: string,
	queryVals: {
		[x: string]: any;
	}
): Promise<ParseObject[]> {
	const query = new Parse.Query(className);
	for (const key in queryVals) {
		query.contains(key, queryVals[key]);
	}
	query.equalTo("active", true);

	return query.find();
}

function searchGPContent(
	searchText: string | RegExp,
	section: string
): Promise<ParseObject[]> {
	// 	`parse.service::searchGPContent(searchText: ${searchText}, section: ${section})`
	// );
	const searchRegex = new RegExp(searchText, "i");

	const titleQuery = new Parse.Query("GPStoreContent");
	titleQuery.matches("title", searchRegex);
	titleQuery.equalTo("testMode", false);
	titleQuery.equalTo("active", true);
	titleQuery.equalTo("app_section", section);

	const descQuery = new Parse.Query("GPStoreContent");
	descQuery.matches("description", searchRegex);
	descQuery.equalTo("testMode", false);
	descQuery.equalTo("active", true);
	descQuery.equalTo("app_section", section);

	const pubQuery = new Parse.Query("GPStoreContent");
	pubQuery.matches("publisher", searchRegex);
	pubQuery.equalTo("testMode", false);
	pubQuery.equalTo("active", true);
	pubQuery.equalTo("app_section", section);

	const query = Parse.Query.or(titleQuery, descQuery, pubQuery);
	query.limit(50);
	return query.find();
}

async function searchGPUsers(searchText: string, fullSearch: boolean): Promise<ParseUser[]> {
	// `parse.service::searchGPUsers(searchText: ${searchText})`
	// );
	const searchRegex = new RegExp(searchText, "i");
	console.log(`Searching for users with string '${searchText}'`);
	// const lNameQuery = new Parse.Query(Parse.User);
	// lNameQuery.startsWith('lastname', searchText);

	// const fNameQuery = new Parse.Query(Parse.User);
	// lNameQuery.startsWith('firstname', searchText);

	const unameQuery = new Parse.Query(Parse.User);
	//unameQuery.startsWith("username", searchText);

	if (fullSearch) {
		unameQuery.matches("username", searchRegex);
	} else {
		unameQuery.startsWith("username", searchText);
	}


	// const emailQuery = new Parse.Query(Parse.User);
	// emailQuery.startsWith("email", searchText);

	// const idQuery = new Parse.Query(Parse.User);
	// idQuery.startsWith("objectId", searchText);

	// const query = new Parse.Query(Parse.User);
	// query.matches("firstname", searchRegex);

	// const lNameQuery = new Parse.Query(Parse.User);
	// lNameQuery.matches('lastname', searchRegex);

	// const fNameQuery = new Parse.Query(Parse.User);
	// fNameQuery.startsWith('firstname', searchText);

	// const lNameQuery = new Parse.Query(Parse.User);
	// lNameQuery.startsWith('lastname', searchText);

	// const unameQuery = new Parse.Query(Parse.User);
	// unameQuery.matches("username", searchRegex);

	// const emailQuery = new Parse.Query(Parse.User);
	// emailQuery.startsWith("email", searchText);

	const idQuery = new Parse.Query(Parse.User);
	idQuery.equalTo("objectId", searchText);

	const query = Parse.Query.or(
		// lNameQuery,
		// fNameQuery,
		unameQuery,
		// emailQuery,
		idQuery
	);
	query.descending("updatedAt");
	query.limit(50);
	return query.find();
	// return results.map((user) => user as ParseUser);
}

function storeSearch(searchText: string | RegExp): Promise<ParseObject[]> {
	return searchGPContent(searchText, "store");
}

function helpSearch(searchText: string | RegExp): Promise<ParseObject[]> {
	return searchGPContent(searchText, "articles");
}

function getJWT(): Promise<string> {
	return Parse.Cloud.run("GetMapKitJWT");
}

function checkTeamCode(
	code: string
): Promise<{ isValid: boolean; teamName: string; message: string }> {
	return Parse.Cloud.run("verifyTeamLink", { code: code });
}

export interface ResponseJSON {
	userJoinedTeam: boolean;
	info: string;
}

async function userJoinWithCode(code: string): Promise<ResponseJSON> {
	const user = Parse.User.current();
	if (user) {
		return Parse.Cloud.run("joinUserToTeam", {
			code: code,
			uid: user.id,
		});
	}
	return {
		userJoinedTeam: false,
		info: "No user logged in",
	};
}

async function createTeamJoinInvite(
	teamID: string,
	roleID: string,
	email?: string,
	count = 1
): Promise<{ message: string; code: string }> {
	return Parse.Cloud.run("createJoinTeamLink", {
		count: count,
		expiration: null,
		email: email,
		teamID: teamID,
		roleID: roleID,
	});
}

async function removeTeamMember(memberID: string): Promise<boolean> {
	try {
		const response = await Parse.Cloud.run("removeTeamMember", {
			mid: memberID,
		});
		return response.memberRemoved;
	} catch (err) {
		return false;
	}
}

async function getTeamByUUID(uuid: string): Promise<GPTeam | undefined> {
	const teamQuery = new Parse.Query("GPTeam");
	teamQuery.equalTo("uuid", uuid);
	const teamObjs = await teamQuery.find();

	if (teamObjs) {
		return GPTeam.newFromParseObj(teamObjs[0]);
	}

	return undefined;
}

async function getTeamByID(tid: string): Promise<GPTeam | undefined> {
	const teamQuery = new Parse.Query("GPTeam");
	const teamObj = await teamQuery.get(tid);
	if (teamObj) {
		return GPTeam.newFromParseObj(teamObj);
	}

	return undefined;
}

async function getMemberByID(id: string): Promise<GPTeamMember | undefined | null> {
	try {
		const memberQuery = new Parse.Query("GPTeamMembers");
		const memberObj = await memberQuery.get(id);

		if (memberObj) {
			return GPTeamMember.newFromParseObj(memberObj);
		}
	} catch (err) {
		console.log(err);
	}
}

async function getRoleByID(id: string): Promise<GPTeamRole | undefined> {
	const roleQuery = new Parse.Query("GPTeamRole");
	const roleObj = await roleQuery.get(id);
	if (roleObj) {
		return GPTeamRole.newFromParseObj(roleObj);
	}
}

async function getRolesForTeam(teamID: string): Promise<GPTeamRole[]> {
	const roleQuery = new Parse.Query("GPTeamRole");
	const PTeam = Parse.Object.extend("GPTeam");
	const team = new PTeam();
	team.id = teamID;
	roleQuery.equalTo("team", team);

	const roleQueryNoTeam = new Parse.Query("GPTeamRole");
	roleQueryNoTeam.doesNotExist("team");
	
	const query = Parse.Query.or(roleQuery, roleQueryNoTeam);

	const roleObjs = await query.find();
	return roleObjs.map((role) => GPTeamRole.newFromParseObj(role));
}

async function getSubscriptionsForUser(
	userID: string
): Promise<GPSubscription[]> {
	const subQuery = new Parse.Query("GPSubscription");
	const user = new Parse.User();
	user.id = userID;
	subQuery.equalTo("user", user);
	const subs = await subQuery.find();
	return subs.map((sub) => GPSubscription.fromParseUser(sub));
}

async function getGenericTeamCode(teamID: string): Promise<string> {
	console.log("Getting generic team code");
	const Team = Parse.Object.extend("GPTeam");
	const team = new Team();
	team.id = teamID;
	const linkQuery = new Parse.Query("GPTeamLink");
	linkQuery.equalTo("team", team);
	// linkQuery.include("role")

	const linkObjs = await linkQuery.find();
	for (const linkObj of linkObjs) {
		if (linkObj.get("count") === -1) {
			try {
				const role = await linkObj.get("role").fetch();
				// const gprole = GPTeamRole.newFromParseObj(role)
				if (
					role &&
					role.get("manageMembers") === false &&
					role.get("owner") === false &&
					role.get("write") === false &&
					role.get("billing") === false
				) {
					console.log("Found generic team code: ", linkObj.id);
					return linkObj.id;
				}
			} catch (error) {
				// move on
			}
		}
	}

	console.log("No generic team code found, generating");

	const roleQuery = new Parse.Query("GPTeamRole");
	roleQuery.equalTo("team", team);
	const roleObjs = await roleQuery.find();

	for (const roleObj of roleObjs) {
		try {
			const gprole = GPTeamRole.newFromParseObj(roleObj);
			if (
				gprole.permissions.canManageMembers === false &&
				gprole.permissions.canManageBilling === false
			) {
				const { message, code } = await createTeamJoinInvite(
					teamID,
					roleObj.id,
					"",
					-1
				);
				return code;
			}
		} catch {
			// move on
		}
	}

	const Role = Parse.Object.extend("GPTeamRole");

	const newRole = new Role({
		title: "member",
		manageMembers: false,
		billing: false,
		read: true,
		write: true,
		team: team,
		minIOSVersion: "6.5",
		minAndroidVersion: "1.0",
	});

	const savedRole = await newRole.save();

	const { message, code } = await createTeamJoinInvite(
		teamID,
		savedRole.id,
		"",
		-1
	);
	return code;
}

async function requestPasswordReset(
	email: string
): Promise<{ success: boolean; message: string }> {
	try {
		const response = await Parse.Cloud.run("passwordResetRequest", {
			email: email,
		});
		return {
			success: response.success,
			message: response.message,
		};
	} catch (err: any) {
		return {
			success: false,
			message: err.message,
		};
	}
}

async function resetPassword(
	code: string,
	password: string
): Promise<{ success: boolean; message: string }> {
	try {
		const response = await Parse.Cloud.run("resetPassword", {
			code: code,
			password: password,
		});
		return {
			success: response.success,
			message: response.message,
		};
	} catch (err: any) {
		return {
			success: false,
			message: err.message,
		};
	}
}

async function checkPasswordResetCode(
	code: string
): Promise<{ isValid: boolean; message: string; user?: string }> {
	try {
		const response = await Parse.Cloud.run("checkPasswordReset", {
			code: code,
		});
		return {
			isValid: response.isValid,
			message: response.message,
			user: response.user,
		};
	} catch (err: any) {
		return {
			isValid: false,
			message: err.message,
		};
	}
}

async function setUserEmail(userID: string, email: string): Promise<boolean> {
	const response = await Parse.Cloud.run("setUserEmail", {
		uid: userID,
		email: email,
	});
	return response.success;
}

async function clearUserData(userID: string): Promise<boolean> {
	const response = await Parse.Cloud.run("clearUserData", {
		uid: userID,
	});
	return response.success;
}

async function deleteUserData(userID: string): Promise<boolean> {
	const response = await Parse.Cloud.run("deleteAllUserData", {
		uid: userID,
	});
	return response.success;
}

async function getUserCount(sinceDate: Date): Promise<number> {
	const response = await Parse.Cloud.run("getUserCount", {
		sinceDate: sinceDate,
	});
	return response;
}

async function getActiveSubscriptionCount(): Promise<number> {
	const subQuery = new Parse.Query("GPSubscription");
	subQuery.greaterThanOrEqualTo("expiration", new Date());
	return subQuery.count();
}

async function mergeUserData(
	mainUserID: string,
	toMergeIDs: string[],
	deleteOnMerge = true
) {
	const toMergIDsStr = toMergeIDs.join(",");
	const response = await Parse.Cloud.run("mergeUserAccounts", {
		mergeTo: mainUserID,
		mergeFrom: toMergIDsStr,
		deleteOnMerge: deleteOnMerge,
	});
	return response.success;
}

async function getAppStoreHistory(otId: string): Promise<HistoryResponse> {
	const response = await Parse.Cloud.run("getStoreTransactionHistory", {
		otId: otId,
	});
	console.log("getAppStoreHistory:", response);
	return response;
}

async function getAppStoreSubscriptionStatus(
	otId: string
): Promise<StatusResponse> {
	const response = await Parse.Cloud.run("getStoreSubscriptionHistory", {
		otId: otId,
	});
	console.log("getAppStoreSubscriptionStatus:", response);
	return response;
}

async function runParseFunction(
	functionName: string,
	params: any
): Promise<any> {
	const response = await Parse.Cloud.run(functionName, params);
	return response;
}

function processNote(note: string): string {
	if (note != null) {
		note.replaceAll("\n", "<br />");
	}
	return note;
}

async function getWaypointDataForSidebare(waypointObject: ParseObject): Promise<{[name: string]: any}> {
	console.log("About to create waypoint from parse obj");
  const waypoint = await GPMapWaypoint.fromParseObject(waypointObject);
	console.log("Created waypoint");
  const className = waypointObject.className;
  const title = waypoint.title;
  const lat = waypoint.latitude;
  const lon = waypoint.longitude;
  const icon = waypoint.iconURL;
  const rId = waypointObject.id;
  const uuid = waypoint.uuid;
	const note = processNote(waypoint.note);
  const waypointData = {
    id: rId,
    uuid,
    className,
    title,
    latitude: lat,
    longitude: lon,
    icon,    
		note
  };
	console.log("created waypoint with title " + title);
  return waypointData;
}

function getRoutetDataForSidebare(parseObject: ParseObject): {[name: string]: any} {
	const boundingExtentLatitude = parseObject.get("boundingExtentLatitude");
	const boundingExtentLongitude = parseObject.get("boundingExtentLongitude");
	const boundingExtent: number[] = [
		boundingExtentLongitude,
		boundingExtentLatitude,
	];
	const boundingOriginLatitude = parseObject.get("boundingOriginLatitude");
	const boundingOriginLongitude = parseObject.get("boundingOriginLongitude");
	const boundingOrigin: number[] = [
		boundingOriginLongitude,
		boundingOriginLatitude,
	];
	const type = parseObject.get("routeType");
	const title = parseObject.get("routeName");
	const uuid = parseObject.get("uuid");
	const note = processNote(parseObject.get("note"));
	let image = parseObject.get("routeStartImageFile");
	if (image== null) {
		image = parseObject.get("routeEndImageFile");
	}

	let icon = null;
	if (image != null) {
		icon = image.url(null);
	}


	const routeData = {
		id: parseObject.id,
		uuid,
		className: parseObject.className,
		title,
		note,
		boundingExtent,
		boundingOrigin,
		type,
		icon
	};

	return routeData;
}

function getNoteDataForSidebare(parseObject: ParseObject): {[name: string]: any} {
	const note = processNote(parseObject.get("note"));
	const uuid = parseObject.get("uuid");
	const noteData = {
		id: parseObject.id,
		uuid,
		className: parseObject.className,
		note,
	};

	return noteData;

}

async function getShareSidebarData(shareId: string): Promise<{ [name: string]: any }> {
	console.log("getShareSidebarData " + shareId);
	const shareItemQuery = new Parse.Query("GPShareItem");
	const GPShare = Parse.Object.extend("GPShare");
  const shareObj = new GPShare();
  shareObj.id = shareId;
  shareItemQuery.equalTo("shareObject", shareObj);
	shareItemQuery.include("shareObject");
	shareItemQuery.include("waypoint");
	shareItemQuery.include("route");
	shareItemQuery.include("folder");
	shareItemQuery.include("note");
	shareItemQuery.include("region");
	shareItemQuery.include("collection");
  shareItemQuery.ascending("sortOrder");
  shareItemQuery.limit(10000);
	const shareItems = await shareItemQuery.find();
	console.log("share items length " + shareItems.length);
	const items = [];
	let title = "Shared Data";
	let titleSet = false;
	for (let index = 0; index < shareItems.length; index++) {
		const item = shareItems[index];

		if (!titleSet) {
			const shareObj = item.get("shareObject");
			const t = shareObj.get("title");
			if (t != null) {
				title = t;
				titleSet = true;
			}
		}

		console.log("checking for waypoint");
		const waypointObject = item.get("waypoint");
    if (waypointObject != null) {
			console.log("found waypoint");
      const waypoint = await getWaypointDataForSidebare(waypointObject);      
      items.push(waypoint);
    }

    const routeObject = item.get("route");
    if (routeObject != null) {
      const route = getRoutetDataForSidebare(routeObject);
      items.push(route);
    }

    // const regionObject = item.get("region");
    // if (regionObject != null) {
    //   const region = await getRegionDataToExport(regionObject);
    //   items.push(region);
    // }

		const noteObject = item.get("note");
		if (noteObject != null) {
			const note = getNoteDataForSidebare(noteObject);
			items.push(note);
		}

    // const collectionObject = item.get("collection");
    // if (collectionObject != null) {
    //   const route = await getCollectionDataToExport(collectionObject);
    //   items.push(route);
    // }

    // const folderObject = item.get("folder");
    // if (folderObject != null) {      
    // }

  }	
  return {
		title,
		items
	};
}

async function getTeamMembers(teamId: string) {
  const members = await Parse.Cloud.run("teamMemberLocations", {teamId: teamId});
	return members;
}

async function getTeamData(teamId: string) {
	let userId = "";
	const user = Parse.User.current();
	if (user != null && user.id != null) {
		userId = user.id;
	}

  const data = await Parse.Cloud.run("teamdata", {teamId: teamId, userId: userId});
	return data;
}

async function getUserData(userId: string) {
  const data = await Parse.Cloud.run("userdata", {userId: userId});
	return data;
}

